define("discourse/plugins/docker_manager/discourse/routes/update", ["exports", "@ember/routing/route", "@ember/service", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/docker_manager/discourse/models/repo"], function (_exports, _route, _service, _decorators, _discourseI18n, _repo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let Upgrade = _exports.default = (_class = class Upgrade extends _route.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "messageBus", _descriptor, this);
      _initializerDefineProperty(this, "upgradeStore", _descriptor2, this);
    }
    model() {
      return _repo.default.findAll();
    }
    activate() {
      this.messageBus.subscribe("/docker/update", this.onUpgradeMessage);
    }
    deactivate() {
      this.messageBus.unsubscribe("/docker/update", this.onUpgradeMessage);
    }
    setupController(controller, model) {
      const discourse = model.find(repo => repo.id === "discourse");
      if (discourse?.branch === "origin/main") {
        // Special case: If the branch is "main" warn user
        controller.appendBannerHtml(_discourseI18n.default.t("admin.docker.main_branch_warning", {
          url: "https://meta.discourse.org/t/17014"
        }));
      }
    }
    onUpgradeMessage(msg) {
      switch (msg.type) {
        case "log":
          this.upgradeStore.consoleOutput = this.upgradeStore.consoleOutput + msg.value + "\n";
          break;
        case "percent":
          this.upgradeStore.progressPercentage = msg.value;
          break;
        case "status":
          this.upgradeStore.upgradeStatus = msg.value;
          // Get the resolved model
          const model = this.modelFor("update");
          if (msg.value === "complete") {
            for (const repo of model) {
              if (repo.upgrading) {
                repo.version = repo.latest?.version;
              }
            }
            this.session.requiresRefresh = true;
          }
          if (msg.value === "complete" || msg.value === "failed") {
            for (const repo of model) {
              repo.upgrading = false;
            }
          }
          break;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "messageBus", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "upgradeStore", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onUpgradeMessage", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "onUpgradeMessage"), _class.prototype)), _class);
});