define("discourse/plugins/docker_manager/discourse/components/docker-manager/console", ["exports", "@glimmer/component", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/did-update", "discourse-common/utils/decorators", "@ember/component", "@ember/template-factory"], function (_exports, _component, _didInsert, _didUpdate, _decorators, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _Console;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  let Console = _exports.default = (_class = (_Console = class Console extends _component.default {
    scrollToBottom(element1) {
      if (this.args.followOutput) {
        element1.scrollTop = element1.scrollHeight;
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div
        class="console-logs"
        {{didInsert this.scrollToBottom}}
        {{didUpdate this.scrollToBottom @output}}
      >
        {{~@output~}}
      </div>
    
  */
  {
    "id": "rUSYTm9D",
    "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"console-logs\"],[4,[32,0],[[30,0,[\"scrollToBottom\"]]],null],[4,[32,1],[[30,0,[\"scrollToBottom\"]],[30,1]],null],[12],[1,[30,1]],[13],[1,\"\\n  \"]],[\"@output\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/docker_manager/discourse/components/docker-manager/console.js",
    "scope": () => [_didInsert.default, _didUpdate.default],
    "isStrictMode": true
  }), _Console), _Console), (_applyDecoratedDescriptor(_class.prototype, "scrollToBottom", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "scrollToBottom"), _class.prototype)), _class);
});